// DATE AND TIME
import { format } from 'date-fns'
import moment from 'moment'

export const convertDate = (inputDate, inputFormat = 'dd-MM-yyyy, hh:mm a') => {
  if (!inputDate) return ''
  else {
    const date = new Date(inputDate).toLocaleString('en-US')
    return date === 'Invalid Date' ? inputDate : format(new Date(date), inputFormat)
  }
}

export const getLast30Days = () => {
  return {
    startTime: moment().subtract(29, 'day').toDate(),
    endTime: moment().endOf('day').toDate(),
  }
}