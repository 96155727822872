// MUIS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.action.active,
    height: 14,
    width: 14,
  },
}))

export default useStyles